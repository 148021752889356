<template>
  <Content :links="links">
    <template v-slot:content>
      <div>
        <template v-if="dailyInvoicesReport">
          <div class="sm:rounded-md mb-3">
            <ul role="list" class="">
              <template v-for="item in dailyInvoicesReport.data" :key="item.id">
                <ShowInvoicesExport :item="item" />
              </template>
            </ul>
          </div>
        </template>

        <template v-else>
          <p class="py-6 text-center dark:text-gray-300">
            Diemžēl nekas netika atrasts
          </p>
        </template>
      </div>
    </template>
  </Content>
</template>

<script>
import {mapGetters} from "vuex";
import invoicesLinks from "../../library/appbar/invoicesLinks";
import ShowInvoicesExport from "@/components/Invoices/InvoicesExport/ShowInvoicesExport"

export default {
  name: "InvoicesExport",
  components: {
    ShowInvoicesExport
  },
  data: () => ({
    fromDate: '',
    toDate: '',
    dailyInvoicesDate: '',
    links: invoicesLinks,
  }),
  created() {
    this.$store.dispatch("setDataLoading", true)
  },
  mounted() {
    this.$store.dispatch('getDailyInvoicesReports')
  },
  computed: {
    ...mapGetters({
      dailyInvoicesReport: 'dailyInvoicesReport',
      loading: "loading",
    })
  },
  methods: {
    filterReports() {
      this.$store.dispatch('getDailyInvoicesReports', {
        from : this.dailyInvoicesReport.from,
        to : this.dailyInvoicesReport.to,
      })
    },
    showInvoiceExport(){
      this.$router.push({ path: '/invoices/export'})
    },
    downloadXmlReport(){
      this.$store.dispatch('getInvoicesXmlReport', {
        from : this.dailyInvoicesReport.from,
        to : this.dailyInvoicesReport.to,
      })
    },

  }
}
</script>

<style>

</style>
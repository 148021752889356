<template>
  <li>
    <item-card>
      <template v-slot:title>
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
          {{item.date}}
        </h3>
      </template>
      <template v-slot:buttons>
        <template v-if="!loading">
          <Button icon="download" @click="downloadXML(item.date)" />
        </template>

        <template v-else>
          <Loading />
        </template>
      </template>

      <template v-slot:content>
        <item-text title="XML">
          <template v-slot:content>
            <div class="font-weight-regular dark:text-gray-200" v-if="!item.xml_downloaded">XML nav lejuplādēts</div>
            <Badge v-else :text="item.xml_downloaded" class="bg-green-500 text-green-100 mr-2 mb-2 md:mb-0" />
          </template>
        </item-text>

        <item-text title="Izmaiņas">
          <template v-slot:content>
            <p class="mt-2 flex items-center text-md font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
              <span v-if="!item.are_updated_invoices">Nav izmaiņu</span>

              <span v-else @click="showDailyInvoices(item.date)" class="cursor-pointer">
                <Badge text="Ir izmaiņas" class="bg-red-500 text-red-100 mr-2 mb-2 md:mb-0" />
              </span>
            </p>

          </template>
        </item-text>

      </template>

      <template v-slot:additional>
        <!--  Display updated invoices list -->
        <template  v-if="dailyInvoicesDate === item.date">
          <div class="my-2 w-full">

            <div class="pa-2 w-full">
              <h3 class="text-base">Labotie dokumenti</h3>

              <div class="w-full ml-auto">
                <Button icon="download" @click="downloadDailyUpdatedXML(item.date)" />

                <Button icon="close" @click="dailyInvoicesDate = ''" />
              </div>
            </div>

              <!-- <DailyInvoicesList :invoiceDate="item.date"/> -->

          </div>
        </template>
      </template>

    </item-card>
  </li>
</template>

<script>
import {mapGetters} from "vuex";
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import Badge from "@/components/Components/Badge"
import Loading from "@/components/Components/Loading";

export default {
  name: "ShowInvoicesExport",
  components: {
    ItemCard,
    ItemText,
    Loading,
    Badge,
  },
  data() {
    return {
      dailyInvoicesDate: '',
    }
  },
  computed: {
    ...mapGetters({
        loading: 'loading',
    })
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    downloadXML(date) {
      this.$store.dispatch('getDailyInvoicesXML', date)
    },
    showDailyInvoices(date){
      this.dailyInvoicesDate = date
    },
    downloadDailyUpdatedXML(date) {
      this.$store.dispatch('getDailyUpdatedXML', date)
      this.dailyInvoicesDate = ''
    },
  }
}
</script>
